import 'lazysizes';

document.addEventListener('DOMContentLoaded', () => {
    const cachebuster = Math.round(new Date().getTime() / 1000);

    fetch(`https://secure.espncdn.com/promotions/ptsa/2022/apps/espnw/json/agenda.json?cb=${cachebuster}`)
        .then((response) => response.json())
        .then((data) => initAgenda(data))
        .catch((err) => console.log('error: ' + err));

    fetch(`https://secure.espncdn.com/promotions/ptsa/2022/apps/espnw/json/data.json?cb=${cachebuster}`)
        .then((response) => response.json())
        .then((data) => initPage(data))
        .catch((err) => console.log('error: ' + err));
});


function initPage(data) {

    document.querySelector('.header__copy').innerText = data.summitDates;
    document.querySelector('.video__chat').innerHTML = data.deadSimpleChat;

    if (data.defaultYoutubeID !== '') {
        const iframe = document.createElement("iframe");
        iframe.setAttribute("allowfullscreen", "");
        iframe.setAttribute("frameborder", "0");

        //===========================================
        // Logic for the YouTube day schedule
        //===========================================

        const currentTime = dayjs().format("dddd, MMM D").replace('Sep ', 'Sept ');
        const currentHour = dayjs().tz("America/New_York");
        // const currentMinute = dayjs().minute();
        const currentCompleteHour = dayjs().format("h:mm")

        console.log("Local TimeZone Hour/Minute: ", currentCompleteHour);
        console.log("NYC timeZone Hour/Minute: ", currentHour.format("h:mm"));


        

        iframe.setAttribute("src", `https://www.youtube.com/embed/${data.defaultYoutubeID}/?autoplay=1&playsinline=1&mute=1`);
        document.querySelector('.video__wrapper').appendChild(iframe);
    } else {
        document.querySelector('.video__wrapper').innerHTML = '<img class="lazyload" data-src="https://secure.espncdn.com/promotions/bsa/2022/apps/espnw/img/video-placeholder.jpg" alt="">';
    }

    if (data.postVideoTextVisible) document.querySelector('.video__post-text').innerHTML = data.postVideoText;


//Twitter
//     document.querySelector('.agenda__twitter-embed').innerHTML = data.twitterEmbed;
//
//     window.twttr = (function (d, s, id) {
//         var t, js, fjs = d.getElementsByTagName(s)[0];
//         if (d.getElementById(id)) return;
//         js = d.createElement(s);
//         js.id = id;
//         js.src = "https://platform.twitter.com/widgets.js";
//         fjs.parentNode.insertBefore(js, fjs);
//         return window.twttr || (t = {
//             _e: [], ready: function (f) {
//                 t._e.push(f)
//             }
//         });
//     }(document, "script", "twitter-wjs"));
//
//     twttr.ready(function (twttr) {
//         twttr.widgets.load();
//         setInterval(function () {
//             twttr.widgets.load();
//         }, 30000);
//     });

//Sponsors
    let sponsors = '';
    data.sponsors.forEach((elem, i) => {
        sponsors += `<li class="sponsors__list-item"><a class="sponsors__link" href="#${elem.name.toLowerCase()}"><img class="sponsors__logo ${elem.name.toLowerCase()} lazyload" data-src="${elem.imgPath}" alt=""></a></li>`;
    });


    document.querySelector('.sponsors__list').innerHTML = sponsors;

    $('.sponsors__link').on('click', function (evt) {
        evt.preventDefault();

        const section = $(evt.currentTarget.hash),
            sectionPos = section.offset().top - 100;

        $('html, body').animate({
            scrollTop: sectionPos
        }, 500);
    });

//Partners
    let partners = '<tbody>';

    data.partners.forEach((elem, i) => {
        partners += `<tr id="${elem.name.toLowerCase().split(' ').join('').split('&').join('')}">
      <td>
        <img class="partners__logo lazyload" data-src="${elem.imgPath}" style="width:${elem.width};" alt="">
      </td>
      <td class="partners__copy">
        <h6>${elem.name}</h6>
        <p>${elem.desc}</p>
      </td>
    </tr>`;
    });

    partners += '</tbody>';

    document.querySelector('.partners__table').innerHTML = partners;

    $(document).on('scroll', function () {
        if ($(document).scrollTop() > 78) {
            $('.header').addClass('header--shrink');
        } else {
            $('.header').removeClass('header--shrink');
        }
    });
}

function initAgenda(data) {
    const currentTime = dayjs().format("dddd, MMM D").replace('Sep ', 'Sept ');

    document.querySelector('.agenda__heading').innerHTML = data.heading;

    let dates = '<tr>',
        agenda = '',
        activeClass = '',
        hiddenClass = '',
        isDateActive = false;

    data.agenda.forEach((elem, i) => {
        const date = dayjs(elem.date),
            formattedDate = date.format("dddd, MMM D").replace('Sep ', 'Sept ');

        if (formattedDate === currentTime) {
            activeClass = 'agenda__date--active';
            hiddenClass = '';
            isDateActive = true;
        } else {
            activeClass = '';
            hiddenClass = 'agenda__table--hidden';
        }

        dates += `<th class="agenda__date ${activeClass}" data-id="day-${i}">${formattedDate}</th>`;
        agenda = '';

        elem.agenda.forEach((elem, i) => {
            if (elem.time !== '' && elem.title !== '') {
                agenda += `<tr>
          <td><span class="agenda__time nowrap">${elem.time}</span></td>
          <td>${elem.title}<br/>${elem.desc}</td>
        </tr>`;
            }
        });


        document.querySelector('.agenda__table').innerHTML += `<tbody class="agenda__body ${hiddenClass}" id="day-${i}">${agenda}</tbody>`;
        agenda = '';
    });

    dates += '</tr>';
    document.querySelector('.agenda__head').innerHTML = dates;

    if (!isDateActive) {
        //If no active day, show first day
        document.querySelector('.agenda__date[data-id="day-0"]').classList.add('agenda__date--active');
        document.querySelector('#day-0').classList.remove('agenda__table--hidden');
    }

    //Event Listeners
    $('.agenda__date').on('click', function (evt) {
        const id = $(this).data('id');

        $('.agenda__date').removeClass('agenda__date--active');
        $(this).addClass('agenda__date--active');

        $('.agenda__body').hide();
        $(`#${id}`).show();
    });
}
